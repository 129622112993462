export const localesPool = [
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.GERMANY',
		value: 'de-DE',
		ref: ['.de', '.nl', '.da', '.sv'],
		dateFNSLocale: 'de',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.UNITED_STATES',
		value: 'en-US',
		ref: ['.en', '.da', '.sv'],
		dateFNSLocale: 'enUS',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.AUSTRIA',
		value: 'de-AT',
		ref: ['.de', '.it'],
		dateFNSLocale: 'deAT',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.SWITZERLAND',
		value: 'de-CH',
		ref: ['.de', '.it', '.fr'],
		dateFNSLocale: 'de',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.ITALY',
		value: 'it-IT',
		ref: ['.de', '.en', '.it', '.fr'],
		dateFNSLocale: 'it',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.NETHERLANDS',
		value: 'nl-NL',
		ref: ['.de', '.en', '.nl', '.da'],
		dateFNSLocale: 'nl',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.FRANCE',
		value: 'fr-FR',
		ref: ['.de', '.en', '.it', '.nl', '.es', '.fr'],
		dateFNSLocale: 'fr',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.SPAIN',
		value: 'es-ES',
		ref: ['.es', '.fr'],
		dateFNSLocale: 'es',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.UNITED_KINGDOM',
		value: 'en-GB',
		ref: ['.en', '.nl'],
		dateFNSLocale: 'enGB',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.AUSTRALIA',
		value: 'en-AU',
		ref: ['.en'],
		dateFNSLocale: 'enAU',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.TURKEY',
		value: 'tr-TR',
		ref: ['.tr', '.de', '.en'],
		dateFNSLocale: 'tr',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.ARABIA.SAUDI_ARABIA',
		value: 'ar-AE',
		ref: ['.en', '.ar'],
		dateFNSLocale: 'ar',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.DENMARK',
		value: 'da-DE',
		ref: ['.da', '.en', '.de', '.nl', '.se'],
		dateFNSLocale: 'da',
	},
	{
		display: 'PROFILE.PREFERENCES.REGION.COUNTRY.SWEDEN',
		value: 'sv-SE',
		ref: ['.sv', '.en', '.de', '.da'],
		dateFNSLocale: 'sv',
	},
];
